import React from "react";
import { useOptions } from "../recoil/useOptions";
import { usePresentation } from "../recoil/usePresentation";
import useFullscreen from "./useFullscreen";
import { useTranslation } from "react-i18next";

const useActions = () => {
  const {
    increaseFontSize,
    decreaseFontSize,
    increasePadding,
    decreasePadding,
    increaseAutoSlideSpeed,
    decreaseAutoSlideSpeed,
    toggleAutoSlide,
    toggleAlignCenter,
  } = useOptions();
  const { presentation, onPrev, onNext, onStopPresentation, onSlideDelay, toggleShowHelp } = usePresentation();
  const { exitFullscreen } = useFullscreen();
  const { t } = useTranslation();

  const onStop = () => {
    exitFullscreen();
    onStopPresentation();
  };

  const actions: {
    kbd: string[];
    kbdChar: string;
    title: string;
    description: string;
    onClick: (e?: React.SyntheticEvent) => void;
  }[] = [
    {
      kbd: ["h"],
      kbdChar: "h",
      title: t("option.toggleHelp"),
      description: "",
      onClick: toggleShowHelp,
    },
    {
      kbd: ["+", "=", "num_add"],
      kbdChar: "+",
      title: t("option.increaseFontsize"),
      description: "",
      onClick: increaseFontSize,
    },
    {
      kbd: ["-", "_", "num_subtract"],
      kbdChar: "-",
      title: t("option.decreaseFontsize"),
      description: "",
      onClick: decreaseFontSize,
    },
    {
      kbd: ["]"],
      kbdChar: "]",
      title: t("option.increaseMargin"),
      description: "",
      onClick: increasePadding,
    },
    {
      kbd: ["["],
      kbdChar: "[",
      title: t("option.decreaseMargin"),
      description: "",
      onClick: decreasePadding,
    },
    {
      kbd: ["c"],
      kbdChar: "c",
      title: t("option.centerAlign"),
      description: "",
      onClick: toggleAlignCenter,
    },
    {
      kbd: ["right", "num_6", "6"],
      kbdChar: "→",
      title: t("option.increaseSlideSpeed"),
      description: "",
      onClick: increaseAutoSlideSpeed,
    },
    {
      kbd: ["left", "num_4", "4"],
      kbdChar: "←",
      title: t("option.decreaseSlideSpeed"),
      description: "",
      onClick: decreaseAutoSlideSpeed,
    },
    {
      kbd: ["space", "num_0", "0"],
      kbdChar: "space",
      title: t("option.autoSlide"),
      description: "",
      onClick: toggleAutoSlide,
    },
    {
      kbd: ["up", "num_8", "8"],
      kbdChar: "↑",
      title: t("option.prev"),
      description: "",
      onClick: () => {
        onPrev();
        onSlideDelay(presentation.INTERACTION_DELAY_SECONDS);
      },
    },
    {
      kbd: ["down", "num_2", "2"],
      kbdChar: "↓",
      title: t("option.next"),
      description: "",
      onClick: () => {
        onNext();
        onSlideDelay(presentation.INTERACTION_DELAY_SECONDS);
      },
    },
    {
      kbd: ["Esc"],
      kbdChar: "Esc",
      title: t("option.stopPrompter"),
      description: "",
      onClick: onStop,
    },
  ];

  return {
    actions,
  };
};

export default useActions;
