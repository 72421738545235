import React, { useEffect, useMemo } from "react";
import hotkeys, { KeyHandler } from "hotkeys-js";
import useActions from "../hooks/useActions";

const ViewerHotkey = () => {
  const { actions } = useActions();
  const viewerHotKeys = useMemo(() => {
    return actions
      .reduce((acc, curr) => {
        return [...acc, ...curr.kbd];
      }, [] as string[])
      .join(",");
  }, [actions]);

  useEffect(() => {
    register();
    return () => unregister();
  });

  const register = () => {
    hotkeys(viewerHotKeys, hotKeyHandler);
  };

  const unregister = () => {
    hotkeys.unbind(viewerHotKeys);
  };

  const hotKeyHandler: KeyHandler = (e, he) => {
    e.preventDefault();
    (e.target as HTMLElement).blur();
    const action = actions.find((row) => row.kbd.includes(he.key));
    if (action?.onClick) {
      action.onClick();
    }
  };

  return <></>;
};

export default ViewerHotkey;
