import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { useRef } from "react";

type Presentation = {
  isOnGoing: boolean;
  isReady: boolean;
  isShowHelp: boolean;
  currentScrollY: number;
  maxScrollY: number;
  pauseSeconds: number;
};

const presentationState = atom<Presentation>({
  key: `presentationState`,
  default: {
    isOnGoing: false,
    isReady: false,
    isShowHelp: true,
    currentScrollY: 0,
    maxScrollY: 0,
    pauseSeconds: 0,
  },
});

const presentationSelector = selector({
  key: `presentationSelector`,
  get: ({ get }) => {
    const presentation = get(presentationState);
    return {
      ...presentation,
      isSlidePause: presentation.pauseSeconds > 0,
      INTERACTION_DELAY_SECONDS: 1,
      START_DELAY_SECONDS: 3,
    };
  },
});

export const usePresentation = () => {
  const presentation = useRecoilValue(presentationSelector);
  const setPresentation = useSetRecoilState(presentationState);
  const pauseMsRef = useRef<NodeJS.Timeout>(null);

  const onStartPresentation = () => {
    setPresentation((prev) => ({
      ...prev,
      isShowHelp: false,
      isOnGoing: true,
      isReady: false,
      currentScrollY: 0,
    }));
  };

  const onStopPresentation = () => {
    setPresentation((prev) => ({
      ...prev,
      isOnGoing: false,
    }));
  };

  const setCurrentScrollY = (positionYOrFn: number | ((prev: number) => number)) => {
    setPresentation((prev) => {
      const v = typeof positionYOrFn === "function" ? positionYOrFn(prev.currentScrollY) : positionYOrFn;
      return {
        ...prev,
        currentScrollY: Math.max(0, Math.min(v, prev.maxScrollY)),
      };
    });
  };

  const onPrev = () => {
    setPresentation((prev) => ({
      ...prev,
      currentScrollY: Math.max(0, prev.currentScrollY - 40),
    }));
  };

  const onNext = () => {
    setPresentation((prev) => ({
      ...prev,
      currentScrollY: Math.min(prev.currentScrollY + 40, prev.maxScrollY),
    }));
  };

  const setMaxScrollY = (maxScrollY: number) => {
    setPresentation((prev) => ({
      ...prev,
      maxScrollY,
    }));
  };

  const setIsReady = () => {
    setPresentation((prev) => ({
      ...prev,
      isReady: true,
    }));
  };

  const toggleShowHelp = () => {
    setPresentation((prev) => ({
      ...prev,
      isShowHelp: !prev.isShowHelp,
    }));
  };

  const onSlideDelay = (pauseSeconds: number) => {
    setPresentation((prev) => ({
      ...prev,
      pauseSeconds,
    }));
    clearInterval(pauseMsRef.current);
    pauseMsRef.current = setInterval(() => {
      setPresentation((prev) => {
        if (prev.pauseSeconds <= 0) {
          clearInterval(pauseMsRef.current);
        }
        return {
          ...prev,
          pauseSeconds: prev.pauseSeconds - 1,
        };
      });
    }, 1000);
  };

  return {
    presentation,
    onStartPresentation,
    onStopPresentation,
    setCurrentScrollY,
    onPrev,
    onNext,
    setMaxScrollY,
    setIsReady,
    toggleShowHelp,
    onSlideDelay,
  };
};
