import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

enum Lang {
  Ko = "ko",
  En = "en",
}

type I18nResourceData = typeof koData;

const koData = {
  translation: {
    description:
      "이 사이트는 어디에서든 웹브라우저로 사용할 수 있는 텔레프롬프터 입니다. 모든 상업적, 비상업적인 사용에 대해 무료입니다.",
    aboutTeleprompter:
      "텔레프롬프터는 전자 시각 문자의 연설문이나 대본을 화자에게 표시해주는 디스플레이 장치의 하나입니다.",
    link: {
      wiki: "https://ko.wikipedia.org/wiki/텔레프롬프터",
    },
    whatIsTeleprompter: "텔레프롬프터에 대해 알고 싶으신가요?",
    presentation: {
      end: "끝",
    },
    editor: {
      title: "프리젠테이션 스크립트",
      placeholder: "발표할 스크립트를 입력하세요.",
    },
    option: {
      option: "옵션",
      theme: "테마",
      fontSize: "텍스트 사이즈",
      margin: "여백",
      slideSpeed: "자동 슬라이드 속도",
      additionalOption: "추가 옵션",
      centerAlign: "중앙정렬",
      fullScreen: "전체화면",
      xReverse: "좌우반전",
      yReverse: "상하반전",
      autoSlide: "슬라이드",
      startPrompter: "텔레프롬프터 시작",
      shortcut: "단축키",
      toggleHelp: "도움말 닫기",
      increaseFontsize: "텍스트 사이즈 크게",
      decreaseFontsize: "텍스트 사이즈 작게",
      increaseMargin: "여백 늘리기",
      decreaseMargin: "여백 줄이기",
      increaseSlideSpeed: "슬라이드 속도 빠르게",
      decreaseSlideSpeed: "슬라이드 속도 느리게",
      prev: "이전",
      next: "다음",
      stopPrompter: "프롬프터 중지",
    },
  },
};

const enData: I18nResourceData = {
  translation: {
    description:
      "This site is a teleprompter that can be used anywhere as a web browser. Free for all commercial and non-commercial use.",
    aboutTeleprompter:
      "A teleprompter, also known as an autocue, is a display device that prompts the person speaking with an electronic visual text of a speech or script.",
    link: {
      wiki: "https://en.wikipedia.org/wiki/Teleprompter",
    },
    whatIsTeleprompter: "Do you want to know about the teleprompter?",
    presentation: {
      end: "End",
    },
    editor: {
      title: "Presentation script",
      placeholder: "Enter the script to present.",
    },
    option: {
      option: "Options",
      theme: "Theme",
      fontSize: "Text size",
      margin: "Margin",
      slideSpeed: "Auto slide Speed",
      additionalOption: "Additional Options",
      centerAlign: "Center alignment",
      fullScreen: "Full screen",
      xReverse: "Turn left and right",
      yReverse: "Turn it upside down",
      autoSlide: "Auto slide",
      startPrompter: "Start the Teleprompter",
      shortcut: "Shortcut",
      toggleHelp: "Toggle help",
      increaseFontsize: "Increase text size",
      decreaseFontsize: "Decrease text size",
      increaseMargin: "Increase margin",
      decreaseMargin: "Decrease margin",
      increaseSlideSpeed: "Increase slide speed",
      decreaseSlideSpeed: "Decrease slide speed",
      prev: "Prev",
      next: "Next",
      stopPrompter: "Stop Prompter",
    },
  },
};

const resources: Resource = {
  [Lang.Ko]: koData,
  [Lang.En]: enData,
};

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
