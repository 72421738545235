import React, { useEffect, useRef, useState } from "react";
import { useDraft } from "../recoil/useDraft";
import { useOptions } from "../recoil/useOptions";
import ViewerHotkey from "./ViewerHotkey";
import ViewerControl from "./ViewerControl";
import useFullscreen from "../hooks/useFullscreen";
import { usePresentation } from "../recoil/usePresentation";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { ViewerHelper } from "./ViewerHelper";
import ScrollIndicator from "./ScrollIndicator";
import { useTranslation } from "react-i18next";

const WrapEl = styled.div<{ full: boolean }>`
  position: relative;
  height: 100vh;
  overflow: hidden;
  user-select: none;
  &:after {
    content: " ";
    width: 10px;
    height: 100vh;
    left: 0;
    top: 0;
    position: absolute;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  }
  ${(props) =>
    props.full &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    `}
`;

const ScrollableEl = styled.div`
  overflow: auto;
  height: 100vh;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

const WrapContentEl = styled.div<{
  isOnGoing: boolean;
  isHorizontalMirror: boolean;
  isVerticalMirror: boolean;
  backgroundColor: string;
}>`
  min-height: 100vh;
  background-color: ${(props) => rgba(props.backgroundColor, 0.8)};
  transform: ${(props) =>
    [props.isHorizontalMirror ? "scaleX(-1)" : "", props.isVerticalMirror ? "scaleY(-1)" : ""].join(" ")};
`;

const TransparentCheckerBoardEL = styled.div`
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
  background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),
    linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
`;

const ViewerEl = styled.div<{
  isOnGoing: boolean;
  color: string;
  fontSize: number;
  horizontalPadding: number;
  backgroundColor: string;
  alignCenter: boolean;
}>`
  display: flex;
  word-break: break-all;
  white-space: break-spaces;
  line-height: 150%;
  background-color: ${(props) => rgba(props.backgroundColor, props.isOnGoing ? 0.9 : 0.6)};
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.fontSize}rem`};
  padding: ${(props) => `30px ${props.horizontalPadding}px`};
  text-align: ${(props) => (props.alignCenter ? "center" : "nitial")};
`;

const ViewerEndEl = styled.div<{ backgroundColor: string; color: string; fontSize: number; isOnGoing: boolean }>`
  background: ${(props) => props.backgroundColor};
  background: ${(props) =>
    `linear-gradient(180deg, ${rgba(props.backgroundColor, props.isOnGoing ? 0.9 : 0.6)} 0%, ${rgba(
      props.backgroundColor,
      0
    )} 100%)`};
  text-align: center;
  color: ${(props) => props.color};
  padding: 25vh 0;
  font-size: ${(props) => `${props.fontSize}rem`};
`;

const Viewer = () => {
  const { draft } = useDraft();
  const { options, toggleAutoSlide } = useOptions();
  const { presentation, setCurrentScrollY, setMaxScrollY, setIsReady } = usePresentation();
  const { onStopPresentation } = usePresentation();
  const { requestFullscreen } = useFullscreen({
    disabled: !options.isFullscreen,
    callbackFn: (isFullscreen: boolean) => {
      if (isFullscreen) {
        return;
      }
      onStopPresentation();
    },
  });
  const { t } = useTranslation();
  const [scrollEvent, setScrollEvent] = useState<React.UIEvent>(null);
  const autoScrollIntervalRafRef = useRef<number>();
  const wrapRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapRef.current && presentation.isOnGoing) {
      requestFullscreen(wrapRef.current);
      return;
    }
  }, [onStopPresentation, presentation.isOnGoing, requestFullscreen]);

  useEffect(() => {
    if (!presentation.isOnGoing) {
      return;
    }
    const calcFigure = () => {
      const maxScrollY = getMaxScrollY();
      setMaxScrollY(maxScrollY);
      const isEnd = options.isVerticalMirror
        ? presentation.currentScrollY <= 0
        : presentation.currentScrollY >= maxScrollY;
      if (!maxScrollY) {
        return;
      }
      if (!presentation.isReady) {
        setCurrentScrollY(options.isVerticalMirror ? maxScrollY : 0);
        setIsReady();
        return;
      }
      if (presentation.isSlidePause) {
        return;
      }
      if (!options.isAutoSlide) {
        autoScrollIntervalRafRef.current && cancelAnimationFrame(autoScrollIntervalRafRef.current);
        return;
      }
      if (isEnd) {
        autoScrollIntervalRafRef.current && cancelAnimationFrame(autoScrollIntervalRafRef.current);
        toggleAutoSlide();
        return;
      }
      setCurrentScrollY((prev) => {
        return prev + options.autoSlideSpeed * 2 * (options.isVerticalMirror ? -1 : 1);
      });
    };
    autoScrollIntervalRafRef.current = requestAnimationFrame(calcFigure);
    return () => {
      autoScrollIntervalRafRef.current && cancelAnimationFrame(autoScrollIntervalRafRef.current);
    };
  }, [
    presentation.currentScrollY,
    presentation.isSlidePause,
    options.isAutoSlide,
    presentation.isOnGoing,
    setCurrentScrollY,
    setMaxScrollY,
    presentation.isReady,
    options.isVerticalMirror,
    setIsReady,
    toggleAutoSlide,
    options.autoSlideSpeed,
  ]);

  useEffect(() => {
    if (!scrollableRef.current) {
      return;
    }
    scrollableRef.current.scrollTo({
      top: presentation.currentScrollY,
    });
  }, [presentation.currentScrollY]);

  const onScroll = (e: React.UIEvent) => {
    setScrollEvent(e);
    setCurrentScrollY((e.target as HTMLDivElement).scrollTop);
  };

  const getMaxScrollY = () => {
    const currentHeight = wrapRef.current?.offsetHeight || 0;
    const draftHeight = contentRef.current?.offsetHeight || 0;
    return Math.floor(draftHeight - currentHeight);
  };

  return (
    <WrapEl full={presentation.isOnGoing} ref={wrapRef}>
      <ScrollableEl ref={scrollableRef} onScroll={onScroll}>
        <TransparentCheckerBoardEL>
          <ScrollIndicator scrollEvent={scrollEvent} />
          <ViewerHelper />
          <ViewerControl />
          <ViewerHotkey />
          <WrapContentEl
            ref={contentRef}
            backgroundColor={options.backgroundColor}
            isOnGoing={presentation.isOnGoing}
            isHorizontalMirror={options.isHorizontalMirror}
            isVerticalMirror={options.isVerticalMirror}
          >
            <ViewerEl
              backgroundColor={options.backgroundColor}
              isOnGoing={presentation.isOnGoing}
              color={options.textColor}
              fontSize={options.fontSize}
              horizontalPadding={options.padding}
              alignCenter={options.isAlignCenter}
            >
              {draft}
            </ViewerEl>

            <ViewerEndEl
              backgroundColor={options.backgroundColor}
              color={options.textColor}
              fontSize={options.fontSize}
              isOnGoing={presentation.isOnGoing}
            >
              {t("presentation.end")}
            </ViewerEndEl>
          </WrapContentEl>
        </TransparentCheckerBoardEL>
      </ScrollableEl>
    </WrapEl>
  );
};

export default Viewer;
