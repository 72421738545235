import React from "react";
import Editor from "./Editor";
import Viewer from "./Viewer";
import TeleprompterOptions from "./TeleprompterOptions";
import styled from "styled-components";
import Footer from "./Footer";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const WrapEl = styled(Container)`
  transition: 0.2s;
`;

const LogoWrap = styled.div`
  margin: 0 0 15px 0;
`;

const EditorWrap = styled.div`
  margin: 10px 0 0 0;
`;

const H1El = styled.h1`
  font-size: 3rem;
  margin: 0 0 5px 0;
  font-weight: normal;
  letter-spacing: -2px;
`;

const H1BoleEl = styled(H1El)`
  color: #fff;
  background: black;
  display: inline-block;
  padding: 0 12px;
  border-radius: 5px;
`;

const NewWindowIcon = styled(OpenInNewIcon)`
  width: 12px;
  margin-left: 2px;
  vertical-align: text-bottom;
`;

const Teleprompter = () => {
  const { t } = useTranslation();

  return (
    <WrapEl maxWidth={false} disableGutters={true}>
      <Grid container={true}>
        <Grid
          item={true}
          lg={6}
          className={"customScroll"}
          p={2}
          sx={{
            height: "100vh",
            overflow: "auto",
            zIndex: 1,
          }}
        >
          <LogoWrap>
            <H1El>
              <b>
                <H1BoleEl>P</H1BoleEl>rompter
              </b>
              .dev
            </H1El>
            <Typography variant={"body1"}>
              {t("description")}
              <br />
              {t("aboutTeleprompter")}
              <br />
              <a href={t("link.wiki")} target={"_blank"} rel="noreferrer">
                {t("whatIsTeleprompter")}
                <NewWindowIcon />
              </a>
            </Typography>
          </LogoWrap>
          <TeleprompterOptions />
          {/*<GoogleAdsense slot={"9160008171"} />*/}
          <EditorWrap>
            <Editor />
          </EditorWrap>
          <Footer />
        </Grid>
        <Grid
          item={true}
          lg={6}
          sx={{
            zIndex: 2,
          }}
        >
          <Viewer />
        </Grid>
      </Grid>
    </WrapEl>
  );
};

export default Teleprompter;
