import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { recoilStorageEffect } from "./recoilStorageEffect";
import sample from "../assets/sample/gettysburg.txt";

const storageEffect = recoilStorageEffect<string>({
  storageKey: "draft",
});

const draftState = atom<string>({
  key: `draftState`,
  default: fetch(sample)
    .then((res) => res.text())
    .then((res) => res.trim()),
  effects: [storageEffect],
});

const draftSelector = selector({
  key: `draftSelector`,
  get: ({ get }) => {
    return get(draftState);
  },
});

export const useDraft = () => {
  const draft = useRecoilValue(draftSelector);
  const setDraft = useSetRecoilState(draftState);

  const setText = (text: string) => {
    setDraft(text);
  };

  return {
    draft,
    setText,
  };
};
