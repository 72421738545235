import React from "react";
import styled from "styled-components";
import useActions from "../hooks/useActions";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { usePresentation } from "../recoil/usePresentation";
import { useTranslation } from "react-i18next";

const WrapEl = styled(Paper)<{ show: string }>`
  transition: 0.5s;
  position: absolute;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.98);
  top: ${(props) => (props.show ? "50%" : "150%")};
  left: ${(props) => (props.show ? "50%" : "150%")};
  transform: translate(-50%, -50%);
  padding: 10px;
  min-width: 280px;
  max-width: 25vw;
`;

const KeyboardEl = styled.kbd`
  border: 1px solid gray;
  font-size: 0.8em;
  box-shadow: 1px 0 1px 0 #eee, 0 2px 0 2px #ccc, 0 2px 0 3px #444;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  margin: 0;
  padding: 1px 5px 1px 5px;
  min-width: 22px;
  display: inline-block;
  text-align: center;
  background: #fff;
  color: #000;
  text-transform: uppercase;
  transform: translateY(-3px);
  &:active {
    transform: translateY(-2px);
  }
`;

const ButtonEl = styled(Button)`
  width: 100%;
  border: 0;
  padding: 3px;
  background: transparent;
  display: block;
  color: #222;
  margin: 5px 0;
  text-transform: none;
  border-bottom: 1px dotted #eee;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const ViewerHelper = () => {
  const { actions } = useActions();
  const { presentation } = usePresentation();
  const { t } = useTranslation();

  return (
    <WrapEl elevation={5} show={presentation.isShowHelp ? "true" : ""}>
      <Divider>
        <Typography variant={"subtitle1"}>{t("option.shortcut")}</Typography>
      </Divider>
      <>
        {actions.map((row) => {
          return (
            <ButtonEl type={"button"} onClick={row.onClick} key={row.kbdChar}>
              <Stack direction={"row"} justifyContent={"space-between"} alignContent={"center"} spacing={2}>
                <KeyboardEl>{row.kbdChar}</KeyboardEl>
                {row.title}
              </Stack>
            </ButtonEl>
          );
        })}
      </>
    </WrapEl>
  );
};
