import { useCallback, useEffect, useState } from "react";
import fscreen from "fscreen";

type Props = {
  disabled?: boolean;
  callbackFn?: (isFullscreen: boolean) => void;
};

const useFullscreen = (props?: Props) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const requestFullscreen = useCallback(
    (el: HTMLElement) => {
      if (props?.disabled) {
        return;
      }
      if (!fscreen.fullscreenEnabled) {
        return;
      }
      if (!!fscreen.fullscreenElement) {
        return;
      }
      fscreen.requestFullscreen(el);
    },
    [props?.disabled]
  );

  const exitFullscreen = useCallback(() => {
    if (!fscreen.fullscreenEnabled) {
      return;
    }
    if (!fscreen.fullscreenElement) {
      return;
    }
    fscreen.exitFullscreen();
  }, []);

  useEffect(() => {
    const onFullscreenChange = () => {
      const isFullscreen = !!fscreen.fullscreenElement;
      setIsFullscreen(isFullscreen);
      if (props?.callbackFn) {
        props.callbackFn(isFullscreen);
      }
    };
    fscreen.addEventListener("fullscreenchange", onFullscreenChange);
    return () => fscreen.removeEventListener("fullscreenchange", onFullscreenChange);
  }, [props]);

  return {
    requestFullscreen,
    exitFullscreen,
    isFullscreen,
  };
};

export default useFullscreen;
