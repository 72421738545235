import { Box, LinearProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

type Props = {
  scrollEvent: React.UIEvent | null;
};

const WrapEl = styled(Box)`
  transition: 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const ScrollIndicator = ({ scrollEvent }: Props) => {
  const [percent, setPercent] = useState(0);

  const onScroll = useCallback((e: React.UIEvent) => {
    const element = e?.target as HTMLElement;
    if (!element) {
      return;
    }
    const ScrollPercent = (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100;
    setPercent(ScrollPercent);
  }, []);

  useEffect(() => {
    if (!scrollEvent) {
      return;
    }
    onScroll(scrollEvent);
  }, [onScroll, scrollEvent]);

  return (
    <WrapEl>
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" value={percent} color={"warning"} />
      </Box>
    </WrapEl>
  );
};

export default ScrollIndicator;
