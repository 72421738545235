import React, { Suspense } from "react";
import { RecoilRoot } from "recoil";
import Teleprompter from "./components/Teleprompter";
import { CustomThemeProvider } from "./providers/ThemeProviders";
import "./i18n";
import Loading from "./components/Loading";

function App() {
  return (
    <RecoilRoot>
      <Suspense fallback={<Loading />}>
        <CustomThemeProvider>
          <Teleprompter />
        </CustomThemeProvider>
      </Suspense>
    </RecoilRoot>
  );
}

export default App;
