import React, { useRef } from "react";
import { useDraft } from "../recoil/useDraft";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { FormControl, FormLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledTextareaAutosize = styled(TextareaAutosize)`
  resize: none;
  word-break: break-all;
  overflow: hidden;
  display: block;
  width: 100%;
  padding: 10px;
  line-height: 160%;
  font-size: 1rem;
`;

const Editor = () => {
  const { draft, setText } = useDraft();
  const { t } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText((e.target as HTMLTextAreaElement).value);
  };

  return (
    <FormControl fullWidth={true}>
      <FormLabel>{t("editor.title")}</FormLabel>
      <StyledTextareaAutosize
        ref={textareaRef}
        value={draft}
        onChange={onChange}
        minRows={10}
        placeholder={t("editor.placeholder")}
      />
    </FormControl>
  );
};

export default Editor;
