import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { recoilStorageEffect } from "./recoilStorageEffect";
import { Decimal } from "decimal.js";

export enum Theme {
  Light = "Light",
  Dark = "Dark",
}

type Options = {
  theme: Theme;
  isMirror: boolean;
  isAutoSlide: boolean;
  isVerticalMirror: boolean;
  isHorizontalMirror: boolean;
  fontSize: number;
  autoSlideSpeed: number;
  isFullscreen: boolean;
  padding: number;
  isAlignCenter: boolean;
};

const storageEffect = recoilStorageEffect<Options>({
  storageKey: "options",
});

const draftViewOptionsState = atom<Options>({
  key: `draftViewOptionState`,
  default: {
    theme: Theme.Dark,
    isMirror: false,
    isHorizontalMirror: false,
    isVerticalMirror: false,
    isAutoSlide: false,
    fontSize: 5,
    autoSlideSpeed: 0.4,
    isFullscreen: false,
    padding: 50,
    isAlignCenter: false,
  },
  effects: [storageEffect],
});

const draftViewOptionsSelector = selector({
  key: `draftViewOptionSelector`,
  get: ({ get }) => {
    const options = get(draftViewOptionsState);
    let backgroundColor: string;
    let textColor: string;
    switch (options.theme) {
      case Theme.Light:
        backgroundColor = "#fff";
        textColor = "#000";
        break;
      case Theme.Dark:
      default:
        backgroundColor = "#000";
        textColor = "#fff";
        break;
    }
    return {
      ...options,
      backgroundColor,
      textColor,
      minFontSize: 0.1,
      maxFontSize: 10,
      fontSizeStep: 0.5,
      minPadding: 0,
      maxPadding: 400,
      paddingStep: 20,
      minAutoSlideSpeed: 0.1,
      maxAutoSlideSpeed: 1,
      autoSlideSpeedStep: 0.1,
    };
  },
});

export const useOptions = () => {
  const options = useRecoilValue(draftViewOptionsSelector);
  const setOptions = useSetRecoilState(draftViewOptionsState);

  const toggleHorizontalMirror = () => {
    setOptions((prev) => ({
      ...prev,
      isHorizontalMirror: !prev.isHorizontalMirror,
    }));
  };

  const toggleVerticalMirror = () => {
    setOptions((prev) => ({
      ...prev,
      isVerticalMirror: !prev.isVerticalMirror,
    }));
  };

  const toggleAutoSlide = () => {
    setOptions((prev) => ({
      ...prev,
      isAutoSlide: !prev.isAutoSlide,
    }));
  };

  const toggleFullScreen = () => {
    setOptions((prev) => ({
      ...prev,
      isFullscreen: !prev.isFullscreen,
    }));
  };

  const toggleAlignCenter = () => {
    setOptions((prev) => ({
      ...prev,
      isAlignCenter: !prev.isAlignCenter,
    }));
  };

  const changeTheme = (theme: Theme) => {
    setOptions((prev) => ({
      ...prev,
      theme: Object.values(Theme).includes(theme) ? theme : Theme.Dark,
    }));
  };

  const increaseAutoSlideSpeed = () => {
    setOptions((prev) => ({
      ...prev,
      autoSlideSpeed: Math.min(
        new Decimal(prev.autoSlideSpeed).add(options.autoSlideSpeedStep).toNumber(),
        options.maxAutoSlideSpeed
      ),
    }));
  };

  const decreaseAutoSlideSpeed = () => {
    setOptions((prev) => ({
      ...prev,
      autoSlideSpeed: Math.max(
        new Decimal(prev.autoSlideSpeed).minus(options.autoSlideSpeedStep).toNumber(),
        options.minAutoSlideSpeed
      ),
    }));
  };

  const changeAutoSlideSpeed = (newSpeed: number) => {
    setOptions((prev) => ({
      ...prev,
      autoSlideSpeed: Math.min(Math.max(newSpeed, options.minAutoSlideSpeed), options.maxAutoSlideSpeed),
    }));
  };

  const increaseFontSize = () => {
    setOptions((prev) => ({
      ...prev,
      fontSize: Math.min(new Decimal(prev.fontSize).add(options.fontSizeStep).toNumber(), options.maxFontSize),
    }));
  };

  const decreaseFontSize = () => {
    setOptions((prev) => ({
      ...prev,
      fontSize: Math.max(new Decimal(prev.fontSize).minus(options.fontSizeStep).toNumber(), options.minFontSize),
    }));
  };

  const changeFontSize = (newSize: number) => {
    setOptions((prev) => ({
      ...prev,
      fontSize: Math.min(Math.max(newSize, options.minFontSize), options.maxFontSize),
    }));
  };

  const increasePadding = () => {
    setOptions((prev) => ({
      ...prev,
      padding: Math.min(new Decimal(prev.padding).add(options.paddingStep).toNumber(), options.maxPadding),
    }));
  };

  const decreasePadding = () => {
    setOptions((prev) => ({
      ...prev,
      padding: Math.max(new Decimal(prev.padding).minus(options.paddingStep).toNumber(), options.minPadding),
    }));
  };

  const changePadding = (newPadding: number) => {
    setOptions((prev) => ({
      ...prev,
      padding: Math.min(Math.max(newPadding, options.minPadding), options.maxPadding),
    }));
  };

  return {
    options,
    toggleHorizontalMirror,
    toggleVerticalMirror,
    toggleAutoSlide,
    toggleFullScreen,
    toggleAlignCenter,
    changeTheme,
    increaseAutoSlideSpeed,
    decreaseAutoSlideSpeed,
    changeAutoSlideSpeed,
    increaseFontSize,
    decreaseFontSize,
    changeFontSize,
    increasePadding,
    decreasePadding,
    changePadding,
  };
};
