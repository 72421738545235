import React, { useMemo } from "react";
import { Theme, useOptions } from "../recoil/useOptions";
import { usePresentation } from "../recoil/usePresentation";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const WrapEl = styled(Paper)`
  background: rgba(0, 0, 0, 0.05);
`;

const TeleprompterOptions = () => {
  const {
    options,
    changeTheme,
    toggleHorizontalMirror,
    toggleVerticalMirror,
    toggleFullScreen,
    toggleAlignCenter,
    toggleAutoSlide,
    changeFontSize,
    changeAutoSlideSpeed,
    changePadding,
  } = useOptions();
  const { presentation, onStartPresentation, onSlideDelay } = usePresentation();
  const { t } = useTranslation();

  const themeList = useMemo(
    () =>
      Object.values(Theme).map((row) => ({
        value: row,
        name: row,
      })),
    []
  );

  const onChangeTheme = (e: SelectChangeEvent) => {
    changeTheme(e.target.value as Theme);
  };

  const onStart = () => {
    onStartPresentation();
    onSlideDelay(presentation.START_DELAY_SECONDS);
  };

  return (
    <>
      <WrapEl variant={"outlined"}>
        <Stack spacing={2} padding={1} paddingY={2}>
          <Grid container={true}>
            <Grid item={true} lg={12} paddingX={1}>
              <FormControl>
                <InputLabel>{t("option.theme")}</InputLabel>
                <Select value={options.theme} label="테마" size={"small"} onChange={onChangeTheme}>
                  {themeList.map((row) => (
                    <MenuItem key={row.value} value={row.value}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item={true} xs={12}>
              <Divider>
                <Typography variant={"caption"}>{t("option.option")}</Typography>
              </Divider>
            </Grid>

            <Grid item={true} lg={4} xs={12} paddingX={1}>
              <FormControl fullWidth={true}>
                <FormLabel>{t("option.fontSize")}</FormLabel>
                <Slider
                  min={options.minFontSize}
                  max={options.maxFontSize}
                  step={options.fontSizeStep}
                  value={options.fontSize}
                  marks={true}
                  size={"small"}
                  onChange={(e, v) => changeFontSize(v as number)}
                />
              </FormControl>
            </Grid>

            <Grid item={true} lg={4} xs={12} paddingX={1}>
              <FormControl fullWidth={true}>
                <FormLabel>{t("option.margin")}</FormLabel>
                <Slider
                  min={options.minPadding}
                  max={options.maxPadding}
                  step={options.paddingStep}
                  value={options.padding}
                  marks={true}
                  size={"small"}
                  onChange={(e, v) => changePadding(v as number)}
                />
              </FormControl>
            </Grid>

            <Grid item={true} lg={4} xs={12} paddingX={1}>
              <FormControl fullWidth={true}>
                <FormLabel>{t("option.slideSpeed")}</FormLabel>
                <Slider
                  min={options.minAutoSlideSpeed}
                  max={options.maxAutoSlideSpeed}
                  step={options.autoSlideSpeedStep}
                  value={options.autoSlideSpeed}
                  marks={true}
                  size={"small"}
                  onChange={(e, v) => changeAutoSlideSpeed(v as number)}
                />
              </FormControl>
            </Grid>

            <Grid item={true} xs={12}>
              <Divider>
                <Typography variant={"caption"}>{t("option.additionalOption")}</Typography>
              </Divider>
            </Grid>

            <Grid item={true} lg={3} paddingX={1}>
              <FormControlLabel
                label={t("option.centerAlign")}
                control={<Switch checked={options.isAlignCenter} onChange={toggleAlignCenter} />}
              />
            </Grid>
            <Grid item={true} lg={3} paddingX={1}>
              <FormControlLabel
                label={t("option.fullScreen")}
                control={<Switch checked={options.isFullscreen} onChange={toggleFullScreen} />}
              />
            </Grid>
            <Grid item={true} lg={3} paddingX={1}>
              <FormControlLabel
                label={t("option.xReverse")}
                control={<Switch checked={options.isHorizontalMirror} onChange={toggleHorizontalMirror} />}
              />
            </Grid>
            <Grid item={true} lg={3} paddingX={1}>
              <FormControlLabel
                label={t("option.yReverse")}
                control={<Switch checked={options.isVerticalMirror} onChange={toggleVerticalMirror} />}
              />
            </Grid>
            <Grid item={true} lg={3} paddingX={1}>
              <FormControlLabel
                label={t("option.autoSlide")}
                control={<Switch checked={options.isAutoSlide} onChange={toggleAutoSlide} />}
              />
            </Grid>
          </Grid>

          <Button onClick={onStart} variant={"contained"}>
            {t("option.startPrompter")}
          </Button>
        </Stack>
      </WrapEl>
    </>
  );
};

export default TeleprompterOptions;
