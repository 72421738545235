import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import styled from "styled-components";

const FooterEl = styled(Grid)`
  margin: 30px 0;
  color: #444;
  line-height: 140%;
`;

const Footer = () => {
  return (
    <FooterEl>
      <Divider />
      <br />
      <Typography variant={"body1"}>
        © 2022 <b>prompter.dev</b> All rights reserved.
      </Typography>
    </FooterEl>
  );
};

export default Footer;
