import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { ReactNode } from "react";

const theme = createTheme({
  typography: {
    fontSize: 13,
  },
});

type Props = {
  children: ReactNode;
};

export const CustomThemeProvider = (props: Props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
