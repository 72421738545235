import React, { useCallback, useEffect, useState } from "react";
import { usePresentation } from "../recoil/usePresentation";
import styled from "styled-components";
import { Box, IconButton } from "@mui/material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { useOptions } from "../recoil/useOptions";
import { differenceInMilliseconds, format, addMinutes } from "date-fns";
import { useTranslation } from "react-i18next";

const WrapEl = styled(Box)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  text-align: center;
  z-index: 9999;
`;

const TimerEl = styled(Box)`
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 5px;
`;

const ButtonEl = styled(IconButton)``;

const ViewerControl = () => {
  const { presentation, toggleShowHelp } = usePresentation();
  const { options } = useOptions();
  const { t } = useTranslation();
  const [baseTimestamp, setBaseTimestamp] = useState<Date>(null);
  const [isRunning, setIsRunning] = useState(false);
  const [stopwatch, setStopwatch] = useState("00:00:00");

  const startStopwatch = useCallback(() => {
    const now = new Date();
    setBaseTimestamp(now);
    setIsRunning(true);
  }, []);

  const resetStopwatch = useCallback(() => {
    const now = new Date();
    setBaseTimestamp(now);
    setIsRunning(false);
  }, []);

  useEffect(() => {
    setIsRunning(presentation.isOnGoing);
    if (presentation.isOnGoing) {
      startStopwatch();
    } else {
      resetStopwatch();
    }
  }, [presentation.isOnGoing, startStopwatch, resetStopwatch]);

  useEffect(() => {
    if (!isRunning) {
      return;
    }
    const interval = setInterval(() => {
      const diff = differenceInMilliseconds(new Date(), baseTimestamp);
      const diffDate = new Date(diff);
      setStopwatch(format(addMinutes(diffDate, diffDate.getTimezoneOffset()), "HH:mm:ss"));
    }, 10);
    return () => clearInterval(interval);
  }, [baseTimestamp, isRunning]);

  return (
    <WrapEl
      sx={{
        color: options.textColor,
      }}
    >
      <TimerEl>{stopwatch}</TimerEl>
      <ButtonEl
        size="small"
        onClick={toggleShowHelp}
        sx={{
          color: options.textColor,
        }}
      >
        {presentation.isShowHelp ? <HelpRoundedIcon /> : <HelpOutlineRoundedIcon />}
        {t("option.shortcut")}
      </ButtonEl>
    </WrapEl>
  );
};

export default ViewerControl;
