import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions } from "@firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCU6PH_jpkAhodJ70Fc-peOmfYhA6IEC3E",
  authDomain: "prompterdev.firebaseapp.com",
  projectId: "prompterdev",
  storageBucket: "prompterdev.appspot.com",
  messagingSenderId: "228711706302",
  appId: "1:228711706302:web:28897b10ea4b50b278b8ef",
  measurementId: "G-JS1J8Q2BW1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
