import { AtomEffect } from "recoil";

export interface Storage {
  get(key: string): Promise<string>;
  set(key: string, value: string): Promise<string>;
  clear(key: string): Promise<unknown>;
}

type RecoilStorageOptions = {
  storageKey?: string;
  storage?: Storage;
  ttl?: number;
};

type RecoilStorageDataStructure = Record<string, string> & {
  ttl: number;
};

const localStorage = (): Storage => {
  const get = (key: string) => {
    return new Promise<string>((resolve) => {
      const value = window.localStorage.getItem(key) as string;
      resolve(value);
    });
  };
  const set = (key: string, value: string) => {
    window.localStorage.setItem(key, value);
    return get(key);
  };

  const clear = (key: string) => {
    return new Promise((resolve) => resolve(window.localStorage.removeItem(key)));
  };

  return {
    get,
    set,
    clear,
  };
};

const DEFAULT_STORAGE_KEY = "recoil-storage";
const DEFAULT_TTL = 1000 * 60 * 60 * 24;

export const recoilStorageEffect = <T extends unknown>(config: RecoilStorageOptions) => {
  const { storageKey = DEFAULT_STORAGE_KEY, storage = localStorage(), ttl = DEFAULT_TTL } = config;

  const getRaw = async (): Promise<Record<string, string>> => {
    const raw = await storage.get(storageKey);
    const data: RecoilStorageDataStructure = JSON.parse(raw);
    if (!data?.ttl || Date.now() > data?.ttl) {
      await storage.clear(storageKey);
      return {};
    }
    return raw ? JSON.parse(raw) : {};
  };

  const getState = async (key: string) => {
    const raw = await getRaw();
    return raw[key];
  };

  const resetState = async (key: string) => {
    const raw = await getRaw();
    delete raw[key];
  };

  const setState = async (newValue: any, key: string) => {
    const raw = await getRaw();
    raw[key] = newValue;
    return storage.set(
      storageKey,
      JSON.stringify({
        ...raw,
        ttl: Date.now() + ttl,
      })
    );
  };

  const effect: AtomEffect<T> = ({ node, trigger, setSelf, onSet }) => {
    const { key } = node;
    if (trigger === "get") {
      getState(key).then((value) => {
        if (!!value) {
          setSelf(value as unknown as T);
        }
      });
    }
    onSet(async (newValue, oldValue, isReset) => {
      if (isReset) {
        return await resetState(key);
      }
      return setState(newValue, key);
    });
  };

  return effect;
};
